import { vertexShader, fragmentShader } from './tube';
import { colorBlue1Vec,  colorBlue2Vec,  colorBlue1FracVec,  colorBlue2FracVec, } from './tube';

AFRAME.registerComponent("server-cooling-tube", {
  schema: {
      flowSpeed: { default: 0.2 }, // Speed of flow
      fraction: { default: 0.5 }, // fraction variable for the "heat" (color) of the cable
  },
  init: function () {
      const startColdToServer = new THREE.Vector3(-9.5, -6, 3);
      const endColdToServer = new THREE.Vector3(-4, 0, 3);
      const flowDirection = new THREE.Vector2(0, 1);
      const radius = 0.2;
      const coldToServerPoints = [
        endColdToServer,
        new THREE.Vector3(startColdToServer.x + 5, startColdToServer.y + 1, startColdToServer.z),
        new THREE.Vector3(startColdToServer.x + 4, startColdToServer.y, startColdToServer.z),
        startColdToServer
      ]
      const coldToServerMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: flowDirection },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
          color1: { value: colorBlue1Vec },
          color2: { value: colorBlue2Vec },
          colorFrac1: { value: colorBlue1FracVec },
          colorFrac2: { value: colorBlue2FracVec },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader
      });
      const coldToServerCurve = new THREE.CatmullRomCurve3(coldToServerPoints);
      const coldToServerGeometry = new THREE.TubeGeometry(coldToServerCurve, 10, radius, 8, false);
      const coldToServerMesh = new THREE.Mesh(coldToServerGeometry, coldToServerMaterial);
      this.el.setObject3D("coldToServerMesh", coldToServerMesh);

      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
  },
   tick: function (time, deltaTime) {
      const dt = (deltaTime / 1000) * this.data.flowSpeed;
  
      // Update time uniforms for shader animation
      this.el.getObject3D("coldToServerMesh").material.uniforms.time.value += dt;
      this.el.getObject3D("coldToServerMesh").material.uniforms.fraction.value = this.data.fraction;
    },
});
